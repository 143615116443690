import React from 'react';
import { Box, Heading, Stack, Link } from "@chakra-ui/react";
import BackgroundImage from '../../images/backgrounds/contact-BR.svg';
import { Link as GatsbyLink } from 'gatsby';
import useTypewriter from '../../hooks/use-typewriter';
import { useBreakpointValue } from "@chakra-ui/react"

export default function ContactSection() {
  const typedText = useTypewriter(["Climb mountains.", "Pat dogs.", "Hug trees.", "Kiss fish.","Take action."]);
  const emailSize = useBreakpointValue(["md", "xl", "2xl"])

  return <Box backgroundImage={`url(${BackgroundImage})`} backgroundPosition="bottom right" backgroundSize={['90%', null, '60%', '60%']} backgroundRepeat="no-repeat" py={150} minHeight="100vh">
    <Box p={["2rem", "2rem", "4rem"]}>
      <Box pb={['8rem']}>
        <Stack spacing={8}>
          <Heading size="2xl">
            {typedText}
            <br/>
            Make ripples.
          </Heading>
          <Link href="mailto:kiaora@makeripples.nz" as={GatsbyLink} isExternal><Heading size={emailSize} color="brand.primary">kiaora@makeripples.nz</Heading></Link>
          {/* <Text>
            Need help using makeripples?
            <br/>
            <Link href="/help" as={GatsbyLink}><Text color="brand.primary">Visit our help center</Text></Link>
          </Text> */}
        </Stack>
      </Box>
    </Box>
  </Box>
}