import * as React from "react"
import { Helmet } from "react-helmet";
import Layout from '../components/layout'
import ContactSection from "../containers/sections/contact-section";
import MakeRipplesSection from "../containers/sections/make-ripples-section";
import OtherPagesSection from "../containers/sections/other-pages-section";

const Contact = ({ location }) => {
  return <Layout activePage={location.pathname}>
    <Helmet>
      <title>makeripples - Contact</title>
    </Helmet>
    <ContactSection />
    <OtherPagesSection activePage={location.pathname} />
    <MakeRipplesSection />
  </Layout>
}

export default Contact;