import { useState, useEffect } from 'react';

const MAX_DELAY_DEFAULT = 100;
const MIN_DELAY_DEFAULT = 50;

export default function useTypewriter(sentences: string[], minKeyDelay: number = MIN_DELAY_DEFAULT, maxKeyDelay: number = MAX_DELAY_DEFAULT) {
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [erasing, setErasing] = useState(false);

  if (maxKeyDelay < minKeyDelay || minKeyDelay < 0) {
    console.error('Invalid max and min key delay specified');
    minKeyDelay = MIN_DELAY_DEFAULT;
    maxKeyDelay = MAX_DELAY_DEFAULT;
  }

  const random = () => {
    return Math.random() * (maxKeyDelay - minKeyDelay) + minKeyDelay;
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (erasing) {
        if (currentIndex > 0) {
          const newIndex = currentIndex - 1;
          setCurrentIndex(newIndex);
          setCurrentText(sentences[currentSentenceIndex].substring(0, newIndex))
        } else {
          setCurrentSentenceIndex((currentSentenceIndex + 1) % sentences.length);
          setTimeout(() => { setErasing(false) }, 500);
        }
      } else {
        if (currentIndex < sentences[currentSentenceIndex].length) {
          setCurrentIndex(currentIndex + 1);
          setCurrentText(currentText + sentences[currentSentenceIndex][currentIndex]);
        } else {
          setTimeout(() => { setErasing(true); }, 2000)
        }
      }
    }, erasing ? 20 : random())
    return () => {
      clearTimeout(timer)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentText, erasing])

  

  return currentText;
}